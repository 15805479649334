.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1306px;
    margin: 66px auto;

    &_midle{
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 527px;
    }
}

.img-screen{
    width: 765px;
    height: 527px;
    margin: auto 0;
}

.footer{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1306px;
    margin: 0 auto;

    &_title{
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size:20px;
    }

    &_sub-title{
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #0057B8;
        font-size:15px;
    }
}

.case{
    display: flex;
    position: relative;
    left: -2px;
    width: 441px;
    height: 780px;
    flex-direction: column;
    justify-content: space-between;
    top: -211px;
    

    &>span:first-child{
        position: relative;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        color: #ffffff;
        font-size: 40px;
    }

    &>span:last-child{
        position: relative;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size: 40px;
        text-transform: uppercase;
        text-align: end;
        margin-left: auto;
    }
}

.dop-info{
    &_title{
        display: block;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size: 40px;
        line-height: 0.9;
        margin: 0 0 10px 0;
    }

    &_sub-title{
        display: block;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size: 16px;
        margin: 0 0 5px 0;
    }
    &_info{
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size: 16px;
    }

    &_list{
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        color: #0057B8;
        font-size: 16px;
    }

}
